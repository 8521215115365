import React from "react";

import SectionTitle from "../../components/Common/SectionTitle";

// About Image
import AboutImage from "../../assets/img/about/about2orange.png";

const AboutText = (props) => {
  return (
    <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60 rtl">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
            <div className="img-part">
              <img className="about-main" src={AboutImage} alt="About Image" />
            </div>
          </div>
          <div className="col-lg-6 pr-70 md-pr-14">
            <div className="about-content ">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title mb-44 md-mb-0"
                subtitleClass="sub-title orange"
                subtitle="حول الجامعة"
                titleClass="title mb-16"
                title="اهلا بكم بموقع جامعة الزاوية الالكتروني"
                descClass="bold-text mb-22"
                description=""
                secondDescClass="desc"
                secondDescription="جامعة الزاوية هي إحدى الجامعات الحكومية المنتشرة في ربوع ليبيا. تأسست سنة 1988 بناءً علي قرار اللجنة الشعبية العامة (سابقاً) رقم (135). وهي عضو في اتحاد الجامعات العربية، واتحاد الجامعات الأفريقية، واتحاد الجامعات الإسلامية.
                تضم ا الجامعة 27 كلية موزعة في مدن: الزاوية، العجيلات، زوارة، شاملة لتخصصات الآداب، وإعداد المعلمين،و التربية البدنية،و القانون،و الاقتصاد، والعلوم، والبيطرية والعلوم الزراعية، والهندسة،والطب البشري، وطب الأسنان، والصيدلة، والتقنية الطبية، والصحة العامة، و تقنية المعلومات. وتقوم الجامعة بإداراتها وكلياتها حالياً على مباني ومنشآت تعليمية حديثة بالمدينة جامعية التي تقع على بعد ستة كيلومترات جنوبي مدينة الزاوية، وهي تتربع على حوالي مئة هكتار، وقد روعيت في مباني هذه المدينة جمالية التصميم الداخلي والخارجي. يبلغ عدد اعضاء هيئة التدريس بالجامعة حوالي 2400 عضو هيئة تدريس. ويبلغ عدد الموظفين حوالي 2700 موظفاً و فنياً."
              />
              {/* Section Title End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutText;
