import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import DegreeSingle from "../../components/Degree/DegreeSingle";

// Images
import image1 from "../../assets/img/degrees/5.jpg";
import image2 from "../../assets/img/degrees/2.jpg";
import image3 from "../../assets/img/degrees/3.jpg";
import image4 from "../../assets/img/degrees/4.jpg";
import image5 from "../../assets/img/degrees/5.jpg";

const Degree = () => {
  return (
    <div
      className="rs-degree style1 modify gray-bg pt-100 pb-70 md-pt-70 md-pb-40"
      style={{ direction: "rtl" }}
    >
      <div className="container">
        <div className="row y-middle">
          <div className="col-lg-4 col-md-6 mb-30">
            {/* Section Title Start */}
            <SectionTitle
              sectionClass="sec-title"
              subtitleClass="sub-title primary"
              subtitle="اعلانات "
              titleClass="title mb-0"
              title="اهم اعلانات و نشاطات الجامعة"
            />
            {/* Section Title End */}
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DegreeSingle
              itemImage={image1}
              title="فتح باب القبول لبعض التخصصات"
              desc="تعلن ادارة الدراسات العليا والتدريب بجامعة الزاوية عن فتح باب القبول لبرامج الدراسات العليا "
              itemLink="#"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DegreeSingle
              itemImage={image2}
              title="ماجستيرالطاقات المتجددة"
              desc="تعلن إدارة الدراسات العليا والتدريب بجامعة الزاوية عن فتح باب القبول لبرامج الدراسات العليا لينل الدرجة العالية ( الماجستير) "
              itemLink="#"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DegreeSingle
              itemImage={image3}
              title="دعوة للإجتماع الدوري لرؤساء مكاتب الجودة"
              desc="أنتم مدعوون لحضور الاجتماع الأول لعام 2022  وذلك يوم الأربعاء الموافق 16 فبراير 2022 . . بالمركب الجامعي الجنوبي على تمام الساعة العاشرة صباحاً "
              itemLink="#"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DegreeSingle
              itemImage={image4}
              title="حواري بين اللغات"
              desc="تعتزم كلية اللغات و الترجمة جامعة الزاوية إقامة نشاط علمي ثقافي حواري بين اللغات: الإنجليزية؛ و الفرنسية"
              itemLink="#"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DegreeSingle
              itemImage={image5}
              title="إدارة المسجل العام تعيد فتح منظومة التسجيل الالكتروني"
              desc="نائنا على قرار وزير التعليم العالى والبحث العلمي رقم (18)لسنة 2022 بشأن توحيد المرحلة العامة للكليات العلمية بالجامعات الليبية
              "
              itemLink="#"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Degree;
