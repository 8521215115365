import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";

// Image
import videoImg from "../../../assets/img/about/about-video-bg2.png";

const CourseSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="inner-column">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="YLN1Argi7ik"
        onClose={() => {
          openModal();
        }}
      />
      <div className="intro-video media-icon orange-color2">
        <img className="video-img" src={videoImg} alt="Video Image" />
        <Link
          className="popup-videos"
          onClick={() => {
            openModal();
          }}
        >
          <i className="fa fa-play"></i>
        </Link>
        <h4>رحلة حول القسم</h4>
      </div>
      <div className="course-features-info ">
        <ul>
          <li className="lectures-feature ">
            <i className="fa fa-files-o"></i>
            <span className="label">3</span>
            <span className="value">عدد القاعات</span>
          </li>

          <li className="quizzes-feature">
            <i className="fa fa-puzzle-piece"></i>
            <span className="label">فصل</span>
            <span className="value">نظام الدراسة</span>
          </li>

          <li className="duration-feature">
            <i className="fa fa-clock-o"></i>
            <span className="value">مدة الدراسة</span>
            <span className="label"> سنوات 4 </span>
          </li>

          <li className="students-feature">
            <i className="fa fa-users"></i>
            <span className="label">90</span>
            <span className="value">عدد الطلبة</span>
          </li>

          <li className="assessments-feature">
            <i className="fa fa-check-square-o"></i>
            <span className="label">لا يوجد</span>
            <span className="value">مشروع تخرج</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CourseSidebar;
