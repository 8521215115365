import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SectionTitle from "../../components/Common/SectionTitle";
import CourseSingleFour from "../../components/Courses/CourseSingleFour";

// Courses Image
import image1 from "../../assets/img/courses/8.jpg";
import image2 from "../../assets/img/courses/8.jpg";
import image3 from "../../assets/img/courses/9.jpg";

import bgImg from "../../assets/img/bg/course-shape-bg.jpg";
const bgStyle = {
  backgroundImage: `url(${bgImg})`,
  "background-size": "cover",
  "background-repeat": "no-repeat",
  "background-position": "center",
};

const Courses = () => {
  const coursesSliderSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div
        className="rs-popular-courses style3 pb-200 md-pb-170 sm-pb-100"
        style={bgStyle}
      >
        <div className="container">
          <div className="row y-middle mb-50 rtl">
            <div className="col-lg-6">
              <SectionTitle
                sectionClass="sec-title"
                subtitleClass="sub-title primary"
                subtitle="اعلانات الكلية"
                titleClass="title mb-0 "
                title="اخر الاعلانات و النشاطات"
              />
            </div>
          </div>
          <Slider {...coursesSliderSettings}>
            <CourseSingleFour
              courseClass="courses-item rtl"
              courseImg={image1}
              courseCategory="اعلان لاعضاء هيئة التدريس "
              courseTitle="اجتماع بقسم الحاسوب"
              btnText="اقرآ المزيد"
            />
            <CourseSingleFour
              courseClass="courses-item rtl"
              courseImg={image2}
              courseCategory="اعلان لطلبة الكلية"
              courseTitle="امتحان البرمجة بقسم الحاسوب"
              btnText="اقرآ المزيد"
            />
            <CourseSingleFour
              courseClass="courses-item rtl"
              courseImg={image3}
              courseCategory="اعلان لموظفي الكلية"
              courseTitle="اجتماع لموظفي الكلية"
              btnText="اقرآ المزيد"
            />
            <CourseSingleFour
              courseClass="courses-item rtl"
              courseImg={image2}
              courseCategory="اعلان لمعيدي الكلية"
              courseTitle="اجتماع بموعيدي قسم الرياضيات"
              btnText="اقرآ المزيد"
            />
          </Slider>
          <div className="view-all-btn text-center pt-50 mb-60 md-pt-30 md-mb-30">
            لقرآءة المزيد من الاعلانات بكلية طب و جراحة الفم والأسنان
            <Link className="title-color" to="/course">
              {" "}
              اضغط هنا
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Courses;
