import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

const CurriculumPart = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);
  return (
    <div className="content">
      <Accordion className="accordion-box" preExpanded={"a"}>
        <AccordionItem className="accordion-item rtl" uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>
              <button>الفصل الاول</button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="card-body acc-content current"></AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="b">
          <AccordionItemHeading>
            <AccordionItemButton>
              <button>الفصل الثاني</button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="card-body acc-content"></AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="c">
          <AccordionItemHeading>
            <AccordionItemButton>
              <button>الفصل الثالث</button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="card-body acc-content"></AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CurriculumPart;
