import React from "react";
import { Link } from "react-router-dom";

const OverviewPart = () => {
  return (
    <div className="content white-bg pt-30">
      <div className="course-overview">
        <div className="inner-box">
          <h4>حول القسم</h4>
          <p className="text-justify">
            حد أقسام كلية طبو جراحةالفم واألسنان املسؤول عن اإلشرافوالتعليم
            واألبحاثفيمجال جراحة الفم واألسنان بالكليةحيثيقوم القسم
            بتدريسمقرراتجراحةالفم والفكينالتيتتضمنطرقاملعالجة الجراحية والغير
            جراحية ألمراض وإصابات وتشوهات منطقة الفم والوجه والفكين ملرحلة السنة
            الثالثة و يقوم القسم بإجراءالعملياتالجراحيةالصغرى وفق
            املوارداملتوفرة. ً البكالوريوس. أيضا .
          </p>
          <h4>رؤية القسم</h4>
          <p>
            توفيرمستوى عاليمن التعليم النظري والسريري فيمجال جراحة الفم
            والوجــــه والفكـــين إلكساب الطالب
            والطلباتاملهاراتالعلميةوالعمليةملمارسة طباألسنان.
          </p>
          <h4>رسالة القسم</h4>
          <p>
            تدريب الطالب والطالبات وفق أعلى املعايير العاملية في التعليم
            والتدريب إلكسابهم مهارات تشخيص . ً األمراضالفمويةوكيفية عالجها
            جراحيا
          </p>

          <h3>أهداف القسم:</h3>
          <ul className="review-list">
            <li>
              تحقيق أعلىمستوياتالتعليم فيمجال جراحةالفم والفكين لتخريج
              أطباءقادرين علىممارسةاملهنةوفق أعلى املعايير.
            </li>
            <li>التدربعلىالعملياتالصغرى الخاصةبالفم والفكين</li>
            <li>
              تحديد أهم األمراضالتيتصيبالفم والوجهوالفكين والعوامل
              املسببةلهاوطرق عالجها
            </li>
            <li>
              إقامة الدورات و الندوات العلمية لرفع من كفاءة أطباء األسنان في
              املجتمع.
            </li>
            <li>تشجيع و متابعة البحوث العلمية في مجال جراحة الفم و األسنان</li>
            <li>
              املساهمة في نشر األبحاث العلمية عالية التأثير في مجال جراحة الفم و
              الفكين
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OverviewPart;
