import React from "react";
import { Link } from "react-router-dom";

const CourseSingleFour = (props) => {
  const {
    courseClass,
    courseCategory,
    courseImg,
    catLink,
    courseTitle,
    btnText,
  } = props;

  return (
    <div className={courseClass ? courseClass : "courses-item"}>
      <div className="img-part">
        <img src={courseImg} alt={courseTitle} />
      </div>
      <div className="content-part">
        <span>
          <Link
            className="categories"
            to={catLink ? catLink : "course-categories"}
          >
            {courseCategory ? courseCategory : "Web Development"}
          </Link>
        </span>

        <h5 className="title">
          <Link to="/course/course-single">
            {courseTitle ? courseTitle : "اعلان مهم"}
          </Link>
        </h5>
        <div className="bottom-part">
          <div className="info-meta"></div>
          <div className="btn-part">
            <Link to="/course/course-single">
              {btnText ? btnText : "Apply Now"}{" "}
              <i className="flaticon-back-1"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSingleFour;
