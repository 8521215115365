import React from "react";
import Slider from "react-slick";
import SinglePostThree from "./SinglePostThree";

import blogImg1 from "../../assets/img/blog/style2/1.jpg";
import blogImg2 from "../../assets/img/blog/style2/2.jpg";
import blogImg3 from "../../assets/img/blog/style2/3.jpg";
import blogImg4 from "../../assets/img/blog/style2/4.jpg";

const BlogPartTwo = () => {
  const blogSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <Slider {...blogSettings}>
        <SinglePostThree
          blogClass="blog-item"
          contentClass="blog-content new-style"
          blogImage={blogImg1}
          blogTitle="High School Program Starting Soon 2021"
          blogDesc="the acquisition of knowledge, skills, values befs, and habits. Educational methods include teach ing, training, storytelling"
          blogPublishedDate="22 مارس 2022"
          blogAuthor="احمد سليمان"
          cmtQnty="15"
        />
        <SinglePostThree
          blogClass="blog-item"
          contentClass="blog-content new-style"
          blogImage={blogImg2}
          blogAuthor="محمود البشتي"
          blogTitle="University class starting soon while the lovely valley team"
          blogDesc="the acquisition of knowledge, skills, values befs, and habits. Educational methods include teach ing, training, storytelling"
          blogPublishedDate="22 مارس 2022"
          cmtQnty="20"
        />
        <SinglePostThree
          blogClass="blog-item"
          contentClass="blog-content new-style"
          blogImage={blogImg3}
          blogAuthor="عدنان الخرزة"
          blogTitle="How universities can nurture for the world climate crisis"
          blogDesc="the acquisition of knowledge, skills, values befs, and habits. Educational methods include teach ing, training, storytelling"
          blogPublishedDate="22 مارس 2022"
          cmtQnty="29"
        />
        <SinglePostThree
          blogClass="blog-item"
          contentClass="blog-content new-style"
          blogImage={blogImg4}
          blogTitle="Oxford vaccine trial resumes in UK after being halted"
          blogDesc="the acquisition of knowledge, skills, values befs, and habits. Educational methods include teach ing, training, storytelling"
          blogPublishedDate="22 مارس 2022"
          cmtQnty="22"
        />
      </Slider>
    </React.Fragment>
  );
};

export default BlogPartTwo;
