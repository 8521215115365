import React from "react";
import Slider from "react-slick";
import Tilty from "react-tilty";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleTestimonial4 from "../../components/Testimonial/SingleTestimonial4";

import animBall from "../../assets/img/shape/circle-shape.png";
import author1 from "../../assets/img/testimonial/style4/1.png";
import author2 from "../../assets/img/testimonial/style4/1.png";
import author3 from "../../assets/img/testimonial/style4/3.png";
import author4 from "../../assets/img/testimonial/style4/4.png";

const Testimonial = () => {
  const testimonialSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="rs-testimonial style4 pt-100 pb-100 md-pt-70 md-pb-70">
        <div className="container relative">
          <div className="left-top-shape">
            <Tilty perspective="800" reverse={true}>
              <img className="rotateme" src={animBall} alt="" />
            </Tilty>
          </div>
          <SectionTitle
            sectionClass="sec-title mb-60 md-mb-30 text-center"
            subtitleClass="sub-title primary"
            subtitle="كلمات"
            titleClass="title mb-0"
            title="أعضاء مجلس ادارة الكلية"
          />
          <Slider {...testimonialSettings}>
            <SingleTestimonial4
              itemClass="testi-item"
              authorImg={author1}
              name="د. محمد عبدالله ارحومة"
              description="            اُرحب ِبكل زوار الموقع الالكتروني لكلية طب وجراحة الفم والاسنان بجامعة الزاوية,حيث يمكنك الحصول على كل المعلومات و الخدمات التي يحتاجها الزائر من الكلية. منذ إنشاء الكلية كشعبة للكليات الطبية سنة 1999م و حتى الآن و الكل يسعى جاهدون للرقي بهذه المؤسسة إلى أعلى المراتب و أرقى المستويات على النطاق المحلي و الإقليمي, كما نسعى دائما لتخريج أطباء أسنان ذو مهارة عالية و ذلك للرفع من مستوى خدمة طب و جراحة الأسنان بالمنطقة. في ظل هذ التقدم الكبير الذي يشهده العالم في مجال طب و جراحة الفم و الاسنان ادعوا اعزائي الطلبة إلى الجد و المثابرة و الاجتهاد لتحقيق النجاح و المساهمة في بناء بلدنا الحبيب ليبيا, و نحثهم دائما للانخراط في الأنشطة خارج اسوار الجامعة للمشاركة في خدمة المجتمع. وفي الختام يسرني أن أتقدم بأسمى آيات الشكر والتقدير لكل العاملين بالكلية من أعضاء هيئة التدريس, معيدين و موظفين الذين يبذلون الجهد والعطاء من اجل خدمة الطلاب وتقديم كل ما هو مفيد لهم بالرغْم من الظروف الصعبة التي تواجههم. أدعوكم لزيارة كليتنا وعياداتها أو الاطلاع على الموقع الاكتروني لكلية طب و جراحة الفم و الاسنان بالزاوية."
              designation="عميد كلية طب و جراحة الفم والاسنان بالزاوية"
            />
            <SingleTestimonial4
              itemClass="testi-item"
              authorImg={author2}
              name="William Stone"
              description="Education is the passport to the future for tomorrow belongs to those who pre pare for it today.Sed ut perspiciatis unde omnis  iste natus error sit vo luptatem accusantium do loremque laudantium,"
              designation="Parent"
            />
            <SingleTestimonial4
              itemClass="testi-item"
              authorImg={author3}
              name="Lara Rose"
              description="Education is the passport to the future for tomorrow belongs to those who pre pare for it today.Sed ut perspiciatis unde omnis  iste natus error sit vo luptatem accusantium do loremque laudantium,"
              designation="Student"
            />
            <SingleTestimonial4
              itemClass="testi-item"
              authorImg={author4}
              name="Astone Martine"
              description="Education is the passport to the future for tomorrow belongs to those who pre pare for it today.Sed ut perspiciatis unde omnis  iste natus error sit vo luptatem accusantium do loremque laudantium,"
              designation="Visitor"
            />
          </Slider>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Testimonial;
